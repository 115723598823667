import { defineStore } from "pinia";
import { axiosInstance } from "@/functions/axiosInstance";
import router from "@/router";

export const useAuthStore = defineStore("auth", {
  // 상태
  state: () => ({
    user: <any>{},
    joinUser: {
      name: "",
      phone: {
        number: "",
        otp: "",
        uid: ""
      },
      gender: "",
      foreigner: false,
      birth: "",
      account: "",
      bank: "",
      id: "",
      password: "",
      email: "",
      marketing: false
    }
  }),
  // computed
  getters: {},
  // method
  actions: {
    joinUserInit() {
      this.joinUser = {
        name: "",
        phone: {
          number: "",
          otp: "",
          uid: ""
        },
        gender: "",
        foreigner: false,
        birth: "",
        account: "",
        bank: "",
        id: "",
        password: "",
        email: "",
        marketing: false
      };
    },
    async createUser() {
      return await axiosInstance
        .post("/member/create", {
          login_id: this.joinUser.id,
          password: this.joinUser.password,
          name: this.joinUser.name,
          gender: this.joinUser.gender,
          foreigner: this.joinUser.foreigner,
          birth: this.joinUser.birth,
          phone: this.joinUser.phone,
          bank: this.joinUser.bank,
          account: this.joinUser.account,
          email: this.joinUser.email,
          marketing: this.joinUser.marketing
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async checkId({ id }) {
      return await axiosInstance
        .post("/member/checkExistId", {
          login_id: id
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async checkPhoneOtp({ uid, otp, phone = "" }) {
      return await axiosInstance
        .post("/member/checkPhoneOtp", {
          uid,
          otp,
          phone
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async checkAccount({ name, birth, bank, account }) {
      return await axiosInstance
        .post("/member/checkAccount", {
          name,
          birth,
          bank,
          account
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async checkPhoneNumber({ name, birth, phone, gender, carrier, mvno, userCheck, foreigner = false }) {
      return await axiosInstance
        .post("/member/checkPhoneNumber", {
          name,
          birth,
          phone,
          gender,
          carrier,
          mvno,
          userCheck,
          foreigner
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async checkCurrentPassword({ password }) {
      return await axiosInstance
        .post("/member/checkCurrentPassword", {
          password
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async changePassword({ password }) {
      return await axiosInstance
        .post("/member/passwordUpdate", {
          password
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async changePasswordToTemp({ id, phone }) {
      return await axiosInstance
        .post("/member/setTempPassword", {
          id,
          phone
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async deleteUser({ password }) {
      return await axiosInstance
        .post("/member/delete", {
          password
        })
        .then((res) => {
          return res;
        })
        .catch((res) => res);
    },
    async updateUser({
      name,
      gender,
      birth,
      phone,
      bank,
      account,
      email,
      marketing,
      firstCheck = false,
      foreigner = false
    }) {
      return await axiosInstance
        .post("/member/update", {
          name,
          gender,
          birth,
          phone,
          bank,
          account,
          email,
          marketing,
          firstCheck,
          foreigner
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async login({ id, password }) {
      return await axiosInstance
        .post("/member/login", {
          id,
          password
        })
        .then((res) => res)
        .catch((res) => res);
    },
    async logout() {
      const logout = await axiosInstance
        .post("/member/logout")
        .then((res) => res)
        .catch((res) => res);
      if (logout.success) {
        useAuthStore().$reset();
        if (router.currentRoute.value.path.includes("/admin")) {
          await router.replace("/admin/login");
        } else {
          await router.replace("/home");
        }
      } else {
        // 토스트
      }
    }
  }
});
