import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
import dayjs from "dayjs";
import { axiosInstance } from "@/functions/axiosInstance";

const mimeTypesToExt = {
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/gif": "gif",
  "image/bmp": "bmp",
  "image/webp": "webp"
};

export const useCommonStore = defineStore("common", {
  // 상태
  state: () => ({
    isLoading: false,
    loadingMessage: "",
    bank: [],
    category: <any>[]
  }),
  // computed
  getters: {},
  // method
  actions: {
    // async getOpenApiData() {
    //   let result = "";
    //   const params = {
    //     serviceKey: import.meta.env.VITE_OPEN_API_KEY,
    //     numOfRows: 10,
    //     pageNo: 1,
    //     dataType: "JSON",
    //     fromTmFc: dayjs().format("YYYYMMDD"),
    //     areaCode: "L1100400"
    //   };
    //   await axios
    //     .get("/api/1360000/WthrWrnInfoService/getPwnCd", { params: params })
    //     .then((res) => {
    //       const data: any = res?.data?.response;
    //       if (_.has(data, "body") && _.has(data.body, "items")) {
    //         const tempData = data.body.items.item[0];
    //         if (tempData.command !== "2" && tempData.command !== "8") {
    //           result =
    //             this.changeCode(tempData.warnVar, "warnVar") + this.changeCode(tempData.warnStress, "warnStress");
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("open Error", e);
    //     });
    //   return result;
    // },
    // changeCode: (code, type) => {
    //   if (type === "warnVar") {
    //     switch (code) {
    //       case 1:
    //         return "강풍";
    //       case 2:
    //         return "호우";
    //       case 3:
    //         return "한파";
    //       case 7:
    //         return "태풍";
    //       case 8:
    //         return "대설";
    //       case 9:
    //         return "황사";
    //       case 12:
    //         return "폭염";
    //       default:
    //         break;
    //     }
    //   } else if (type === "warnStress") {
    //     switch (code) {
    //       case 0:
    //         return "주의보";
    //       case 1:
    //         return "경보";
    //       default:
    //         break;
    //     }
    //   }
    // }
    makeDaysOdMonth(viewDate: any) {
      const ranges = [] as any;
      const startOfRange = viewDate.startOf("month").add(-1, "day");
      const endOfRange = viewDate.endOf("month").add(-1, "day");

      let currentDate = startOfRange;

      while (currentDate.isBefore(endOfRange) || currentDate.isSame(endOfRange)) {
        currentDate = currentDate.add(1, "day");
        ranges.push(currentDate);
      }

      return ranges;
    },
    async getHoliday(params: { year: any; month?: any }) {
      const getResult = await axiosInstance
        .post("/system/getHolidayList", {
          year: params.year,
          month: params.month
        })
        .then((res) => res)
        .catch((res) => res);
      return getResult.result;
    },
    async getSystemCode({ type }) {
      const getResult = await axiosInstance
        .post("/system/list", {
          type
        })
        .then((res) => res)
        .catch((res) => res);
      return getResult.result;
    },
    async extractBlobUrlToFile({ urls, key }) {
      const files: any[] = [];
      for (const blobUrl of urls) {
        if (blobUrl.startsWith("blob:")) {
          // 랜덤 파일 이름 생성
          const randomString = Math.random().toString(36).substr(2, 9);
          const response = await fetch(blobUrl);
          const blob = await response.blob();
          const ext = mimeTypesToExt[blob.type] || "";
          // 확장자를 포함한 파일 이름 생성
          const fileName = `${key}_${randomString}.${ext}`;
          const file = new File([blob], fileName, { type: blob.type });
          files.push(file);
        }
      }
      return files;
    },
    async deleteQuillContent({ target_type, target_id, filesList }) {
      const getResult = await axiosInstance
        .post("/file/quillEditorDeleteImages", {
          target_type,
          target_id,
          filesList
        })
        .then((res) => res)
        .catch((res) => res);
      return getResult.result;
    },
    async getPopupBannerList(param: { master_type: string; is_range?: any }) {
      const getResult = await axiosInstance
        .post("/popupBanner/list", {
          master_type: param.master_type,
          is_range: param.is_range
        })
        .then((res) => res)
        .catch((res) => res);
      return getResult.result;
    }
  }
});
