import { Capacitor } from "@capacitor/core";

let onClick, onMouseUp, onMouseMove, onMouseDown;

export default {
  updated(el, binding) {
    if (binding.arg === "drag" && !binding.value) {
      el.removeEventListener("mousemove", onMouseMove);
      el.removeEventListener("mousedown", onMouseDown);
      el.removeEventListener("mouseup", onMouseUp);
    } else {
      el.addEventListener("mousemove", onMouseMove);
      el.addEventListener("mousedown", onMouseDown);
      el.addEventListener("mouseup", onMouseUp);
    }
  },
  mounted(el) {
    let isDragging = false; // 드래그 판별
    let startX = 0; // 시작 X좌표
    let scrollStartX = 0; // 시작 스크롤
    let dragged = false; // 드래그 동작 발생 유무
    let originalScrollBehavior = ""; // 원래의 scroll-behavior 저장
    // 마우스 버튼 다운
    onMouseDown = (e) => {
      isDragging = true; // 드래깅 시작
      startX = e.clientX; // 마우스 X좌표 저장
      scrollStartX = el.scrollLeft; // 스크롤 값 저장
      originalScrollBehavior = el.style.scrollBehavior; // 현재 scroll-behavior 저장
      el.style.scrollBehavior = "auto"; // scroll-behavior를 auto로 변경
      el.style.userSelect = "none"; // 텍스트 선택 방지
      el.style.cursor = "grabbing"; // 드래그 시 커서 모양
      dragged = false; // 드래그 일어나지 않음
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    // 마우스 이동중
    onMouseMove = (e) => {
      if (!isDragging) return; // 드래그 중이 아니라면 return
      const dx = e.clientX - startX; // 드래그 거리 계산
      if (dx !== 0) {
        dragged = true; // 이동거리가 0보다 크면 드래그 발생
        el.scrollLeft = scrollStartX - dx; // 처음 스크롤에서 이동 거리만큼 스크롤 발생
      }
    };

    // 마우스 업
    onMouseUp = (e) => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      el.style.scrollBehavior = originalScrollBehavior; // 원래의 scroll-behavior로 복원
      el.style.userSelect = "";
      el.style.cursor = "";
      if (dragged) {
        e.preventDefault(); // 드래그 발생했다면 드래그 종료 시 child 클릭 이벤트 억제
      }
      isDragging = false;
    };

    onClick = (e) => {
      if (dragged) {
        // 드래그일때 클릭 이벤트 억제
        e.stopPropagation();
        e.preventDefault();
      }
    };

    if (!Capacitor.isNativePlatform()) {
      el.addEventListener("mousedown", onMouseDown);
      el.addEventListener("click", onClick, true); // 캡처링 단계에서 클릭 이벤트 처리

      el.__cleanup__ = () => {
        el.removeEventListener("mousedown", onMouseDown);
        el.removeEventListener("click", onClick, true);
      };
    }
  },
  unmounted(el) {
    if (!Capacitor.isNativePlatform()) {
      el.__cleanup__ && el.__cleanup__();
    }
  }
};
