import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { IonicVue, isPlatform } from "@ionic/vue";
import Vue3Marquee from "vue3-marquee";

/* dayjs */
import dayjs from "dayjs";
import "dayjs/locale/ko";
import isSameOfAfter from "dayjs/plugin/isSameOrAfter"
dayjs.locale("ko");
dayjs.extend(isSameOfAfter)

import VueCountdown from "@chenfengyuan/vue-countdown";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
/* vueDragScroll */
import vueDragScroll from "@/functions/vue-drag-scroll";

/* quill */
import EditorComponent from "@/components/quill/";

/* VCalendar */
import VCalendar from "v-calendar";
import "v-calendar/style.css";

/* vxe-table */
import VxeTable from "vxe-table";
import enUS from "vxe-table/lib/locale/lang/en-US";
import "vxe-table/lib/style.css";

import VxeUI from "vxe-pc-ui";
import "vxe-pc-ui/lib/style.css";

VxeTable.setI18n("en-US", enUS);
VxeTable.setLanguage("en-US");

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import '@ionic/vue/css/palettes/dark.always.css'; */
/* @import '@ionic/vue/css/palettes/dark.class.css'; */
import "@ionic/vue/css/palettes/dark.system.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/typography.css";
import "./theme/tailwind.css";

import DisableDevtool from 'disable-devtool';

if(import.meta.env.MODE === "production") DisableDevtool();

const pinia = createPinia();

const getConfig = () => {
  let config: any = {
    mode: "ios"
  };
  if (isPlatform("iphone") && isPlatform("mobileweb")) {
    config = {
      ...config,
      swipeBackEnabled: false
    };
  }
  return config;
};
const app = createApp(App)
  .use(IonicVue, getConfig())
  .use(router)
  .use(pinia)
  .use(Vue3Marquee)
  .use(VCalendar, {})
  .use(VxeTable)
  .use(Vue3Toasity, {
    limit: 1,
  } as ToastContainerOptions)
  .use(VxeUI)
  .component(VueCountdown.name as string, VueCountdown)
  .component("Editor", EditorComponent.Editor)
  .directive("drag-scroll", vueDragScroll);

router.isReady().then(() => {
  app.mount("#app");
});
