<template>
  <div class="flex flex-col px-[16px] py-[24px] gap-y-[16px]">
    <div class="flex items-center gap-x-[12px] cursor-pointer" @click="actionButton('announce')">
      <announceIcon />
      <div class="text-[14px] text-base-black-main">공지사항</div>
    </div>
    <div class="flex items-center gap-x-[12px] cursor-pointer" @click="actionButton('QnA')">
      <questionIcon />
      <div class="text-[14px] text-base-black-main">자주 묻는 질문</div>
    </div>
    <div class="flex items-center gap-x-[12px] cursor-pointer" @click="actionButton('directions')">
      <pathIcon />
      <div class="text-[14px] text-base-black-main">오시는 길</div>
    </div>
    <div class="flex items-start gap-x-[12px] cursor-pointer" @click="btnCall">
      <csIcon />
      <div class="flex flex-col">
        <div class="text-[14px] text-base-black-main">고객센터 문의하기 (02-2012-3810)</div>
        <div class="text-[12px] text-base-black-600">(평일 09:00 ~ 18:00)</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import announceIcon from "@/assets/svg/announceIcon_24.svg?component";
import questionIcon from "@/assets/svg/questionIcon_24.svg?component";
import pathIcon from "@/assets/svg/pathIcon_24.svg?component";
import csIcon from "@/assets/svg/csIcon_24.svg?component";

const emit = defineEmits(["action-fn"]);

const btnCall = () => {
  const element = document.createElement("a");
  element.setAttribute("href", "tel:02-2012-3810");
  element.style.display = "none"; //하이퍼링크 요소가 보이지 않도록 처리
  document.body.appendChild(element); //DOM body요소에 하이퍼링크 부착
  element.click(); //클릭 이벤트 트리거 - 이 시점에 다운로드 발생
  document.body.removeChild(element); //하이퍼링크 제거
};

const actionButton = (type: string) => {
  emit("action-fn", type);
};
</script>
