import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/home"
  },
  {
    path: "/admin/login",
    name: "adminLogin",
    component: () => import("@/views/admin/pageLogin.vue"),
    meta: {
      login: false
    }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/pageMain.vue")
  },
  {
    path: "/admin/",
    component: () => import("@/components/admin/adminMenu.vue"),
    children: [
      {
        path: "",
        redirect: "/admin/reservationTable"
      },
      {
        path: "groundMng",
        name: "groundMng",
        component: () => import("@/views/admin/pageGroundManage.vue")
      },
      {
        path: "reservationMng",
        name: "reservationMng",
        component: () => import("@/views/admin/pageReservationManage.vue")
      },
      {
        path: "reservationTable",
        name: "reservationTable",
        component: () => import("@/views/admin/pageReservationTable.vue")
      },
      {
        path: "boardMng",
        name: "boardMng",
        component: () => import("@/views/admin/pageBoardManage.vue")
      },
      {
        path: "makeBoard",
        name: "makeBoard",
        component: () => import("@/views/admin/pageBoardMake.vue"),
        props: true
      },
      {
        path: "makeQnA",
        name: "makeQnA",
        component: () => import("@/views/admin/pageBoardMake.vue"),
        props: true
      },
      {
        path: "boardDetail",
        name: "adminBoardDetail",
        component: () => import("@/views/admin/pageBoardDetail.vue"),
        props: true
      },
      {
        path: "boardModify",
        name: "boardModify",
        component: () => import("@/views/admin/pageBoardMake.vue"),
        props: true
      },
      {
        path: "bannerMng",
        name: "bannerMng",
        component: () => import("@/views/admin/pageBannerManage.vue"),
        props: true
      },
      {
        path: "popupMng",
        name: "popupMng",
        component: () => import("@/views/admin/pagePopupManage.vue"),
        props: true
      },
      {
        path: "smsStatus",
        name: "smsStatus",
        component: () => import("@/views/admin/pageSmsTable.vue"),
        props: true
      },
      {
        path: "userMng",
        name: "userMng",
        component: () => import("@/views/admin/pageUserManage.vue"),
        props: true
      },
      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("@/views/admin/pageUserDetail.vue"),
        props: true
      },
      {
        path: "adminMng",
        name: "adminMng",
        component: () => import("@/views/admin/pageAdminManage.vue"),
        props: true
      },
      {
        path: "adminSetting",
        name: "adminSetting",
        component: () => import("@/views/admin/pageAdminSetting.vue"),
        props: true
      }
    ],
    meta: {
      login: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/user/pageLogin.vue"),
    meta: {
      login: false
    }
  },
  {
    path: "/firstCheck",
    name: "firstCheck",
    component: () => import("@/views/user/pageFirstCheck.vue"),
    meta: {
      login: true
    }
  },
  {
    path: "/findId",
    name: "findId",
    component: () => import("@/views/user/pageFindId.vue"),
    meta: {
      login: false
    }
  },
  {
    path: "/findPw",
    name: "findPw",
    component: () => import("@/views/user/pageFindPw.vue"),
    meta: {
      login: false
    }
  },
  {
    path: "/signIn",
    name: "signIn",
    component: () => import("@/views/user/pageSignIn.vue"),
    meta: {
      login: false
    }
  },
  {
    path: "/modifyUser",
    name: "modifyUser",
    component: () => import("@/views/user/pageModifyUser.vue"),
    meta: {
      login: true
    }
  },
  {
    path: "/modifyPassword",
    name: "modifyPassword",
    component: () => import("@/views/user/pageModifyPassword.vue"),
    meta: {
      login: true
    }
  },
  {
    path: "/deleteUser",
    name: "deleteUser",
    component: () => import("@/views/user/pageDeleteUser.vue"),
    meta: {
      login: true
    }
  },
  {
    path: "/reservation",
    name: "reservation",
    props: true,
    component: () => import("@/views/reservation/pageReservation.vue")
  },
  {
    path: "/reservationStatus",
    name: "reservationStatus",
    component: () => import("@/views/reservation/pageReservationStatus.vue"),
    meta: {
      login: true
    }
  },
  {
    path: "/announce",
    name: "announce",
    props: true,
    component: () => import("@/views/common/pageBoard.vue")
  },
  {
    path: "/QnA",
    name: "QnA",
    props: true,
    component: () => import("@/views/common/pageQnA.vue")
  },
  {
    path: "/boardDetail/:serial_number",
    name: "boardDetail",
    props: true,
    component: () => import("@/views/common/pageBoardDetail.vue")
  },
  {
    path: "/policy/:policyType",
    name: "policy",
    props: true,
    component: () => import("@/views/common/pagePolicy.vue")
  },
  {
    path: "/directions",
    name: "directions",
    props: true,
    component: () => import("@/views/common/pageDirections.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/common/pagePrivacy.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/common/pageTerms.vue")
  }
];

export default routes;
